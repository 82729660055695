import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Nivel from "./Nivel";
import Bomba from "./Bomba";
import Vazao from "./Vazao";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      json: { nivel: [], bomba: [], vazao: [] },
      loading: true,
    };
    this.buttonLoading = this.buttonLoading.bind(this);
    this.callApi = this.callApi.bind(this);
  }
  async componentDidMount() {
    this.setState({
      loading: true,
    });
    await this.callApi();
    setInterval(async () => {
      this.setState({
        loading: true,
      });
      await this.callApi();
    }, 1000 * 60);
  }
  async callApi() {
    const API = process.env.REACT_APP_URL_API;
    const SERVER = process.env.REACT_APP_URL_SERVER;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Origin", SERVER);

    return await fetch(API, {
      mode: "cors",
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          json,
          loading: false,
        });
      });
  }
  buttonLoading() {
    return (
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Carregando...
      </Button>
    );
  }
  render() {
    const { loading } = this.state;
    const { nivel, bomba, vazao } = this.state.json;
    return (
      <main className="text-center justify-content-between">
        {loading ? this.buttonLoading() : ""}
        {nivel && nivel.length > 0 ? (
          <div className="container">
            <h4 className="card-title">Níveis</h4>
            <Nivel nivel={nivel} />
          </div>
        ) : (
          <div></div>
        )}
        {bomba && bomba.length > 0 ? (
          <div className="container">
            <h4 className="card-title">Bombas</h4>
            <Bomba bomba={bomba} />
          </div>
        ) : (
          <div></div>
        )}
        {vazao && vazao.length > 0 ? (
          <div className="container">
            <h4 className="card-title">Medidores de Vazão</h4>
            <Vazao vazao={vazao} />
          </div>
        ) : (
          <div></div>
        )}
      </main>
    );
  }
}

export default Main;
